import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { FlamelinkService } from '../services/flamelink.service';
import { ProjectContent } from '../models/project-content';

@Component({
  selector: 'app-projects',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.scss'],
})
export class ProjectListComponent implements OnInit {
  category: string;
  projects: ProjectContent[];

  constructor(private route: ActivatedRoute, private fl: FlamelinkService) {}

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.category = params.get('category');

      this.fl.getApp().content.subscribe({
        schemaKey: 'project',
        callback: (error, data) => {
          if (error) {
            return console.error(error);
          }

          this.projects = Object.values(data);
          this.projects = this.projects.filter(
            (p) => p.category === this.category
          );
        },
      });
    });
  }
}
