import { Component, OnInit, ViewChild } from '@angular/core';
import { FlamelinkService } from '../services/flamelink.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { ProjectContent } from '../models/project-content';
import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-project-detail',
  templateUrl: './project-detail.component.html',
  styleUrls: ['./project-detail.component.scss'],
})
export class ProjectDetailComponent implements OnInit {
  project: ProjectContent;
  @ViewChild('carousel', { static: true }) carousel: NgbCarousel;

  constructor(private route: ActivatedRoute, private fl: FlamelinkService) {}

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.fl
        .getApp()
        .content.getByField({
          schemaKey: 'project',
          field: 'slug',
          value: params.get('id'),
          populate: true,
        })
        .then((data) => {
          this.project = data[Object.keys(data)[0]];
        });
    });
  }
}
