import { Component, OnInit } from '@angular/core';
import { FlamelinkService } from '../services/flamelink.service';
import { HomeContent } from '../models/home-content';
import { ProjectContent } from '../models/project-content';
import { SiteContent } from '../models/site-content';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  siteContent: SiteContent;
  pageContent: HomeContent = new HomeContent();
  projects: ProjectContent[];
  projectCategories: string[] = [];

  constructor(private fl: FlamelinkService) {}

  ngOnInit() {
    this.fl.getApp().content.subscribe({
      schemaKey: 'siteContent',
      callback: (error, data) => {
        if (error) {
          return console.error(error);
        }

        this.siteContent = data;
      },
    });

    this.fl.getApp().content.subscribe({
      schemaKey: 'home',
      callback: (error, data) => {
        if (error) {
          return console.error(error);
        }

        this.pageContent = data;
      },
    });

    // this.fl.getApp().content.subscribe({
    //   schemaKey: 'project',
    //   callback: (error, data) => {
    //     if (error) {
    //       return console.error(error);
    //     }

    //     this.projects = Object.values(data);
    //     this.projects.forEach((p) => {
    //       this.projectCategories.push(p.category);
    //     });
    //     this.projectCategories = Array.from(new Set(this.projectCategories));
    //   },
    // });
  }
}
