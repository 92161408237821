import { Component, OnInit } from '@angular/core';
import { SiteContent } from '../models/site-content';
import { FlamelinkService } from '../services/flamelink.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { faHome } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  siteContent: SiteContent;
  path: string;
  faHome = faHome;

  constructor(private router: Router, private fl: FlamelinkService) {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.path = val.url;
      }
    });
  }

  ngOnInit() {
    this.fl.getApp().content.subscribe({
      schemaKey: 'siteContent',
      callback: (error, data) => {
        if (error) {
          return console.error(error);
        }

        this.siteContent = data;
      },
    });
  }
}
