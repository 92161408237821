// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyDrT1umbw91SvtrN6BlQG66tVCjXzzjG3Y',
    authDomain: 'justinschneidercom.firebaseapp.com',
    databaseURL: 'https://justinschneidercom.firebaseio.com',
    projectId: 'justinschneidercom',
    storageBucket: 'justinschneidercom.appspot.com',
    messagingSenderId: '568415922659',
    appId: '1:568415922659:web:aaad523b310191e63a58d6',
    measurementId: 'G-L5FGPR2013'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
